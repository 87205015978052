/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// export const registerServiceWorker = () => true
import './packages/ui/styles/bootstrap.scss';

import { render, hydrate } from 'react-dom';
import { loadableReady } from '@loadable/component';

import moment from 'moment-timezone';
import 'moment/locale/th';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

moment.locale('th');
moment.tz('Asia/Bangkok');

export const replaceHydrateFunction = (_, options) => (element, container, callback) => {
  loadableReady(() => {
    const renderFn = process.env.GATSBY_BUILD_STAGE.includes('develop') ? render : hydrate;
    renderFn(element, container, callback);
  });
};
