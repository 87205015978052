// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-th-index-tsx": () => import("./../../../src/pages/th/index.tsx" /* webpackChunkName: "component---src-pages-th-index-tsx" */),
  "component---src-templates-auth-index-tsx": () => import("./../../../src/templates/auth/index.tsx" /* webpackChunkName: "component---src-templates-auth-index-tsx" */),
  "component---src-templates-car-with-driver-index-tsx": () => import("./../../../src/templates/car-with-driver/index.tsx" /* webpackChunkName: "component---src-templates-car-with-driver-index-tsx" */),
  "component---src-templates-city-index-tsx": () => import("./../../../src/templates/city/index.tsx" /* webpackChunkName: "component---src-templates-city-index-tsx" */),
  "component---src-templates-extend-rental-index-tsx": () => import("./../../../src/templates/extend-rental/index.tsx" /* webpackChunkName: "component---src-templates-extend-rental-index-tsx" */),
  "component---src-templates-help-content-tsx": () => import("./../../../src/templates/help/content.tsx" /* webpackChunkName: "component---src-templates-help-content-tsx" */),
  "component---src-templates-help-index-tsx": () => import("./../../../src/templates/help/index.tsx" /* webpackChunkName: "component---src-templates-help-index-tsx" */),
  "component---src-templates-inter-index-tsx": () => import("./../../../src/templates/inter/index.tsx" /* webpackChunkName: "component---src-templates-inter-index-tsx" */),
  "component---src-templates-long-term-rental-index-tsx": () => import("./../../../src/templates/long-term-rental/index.tsx" /* webpackChunkName: "component---src-templates-long-term-rental-index-tsx" */),
  "component---src-templates-long-term-rental-successfully-tsx": () => import("./../../../src/templates/long-term-rental/successfully.tsx" /* webpackChunkName: "component---src-templates-long-term-rental-successfully-tsx" */),
  "component---src-templates-promotions-index-tsx": () => import("./../../../src/templates/promotions/index.tsx" /* webpackChunkName: "component---src-templates-promotions-index-tsx" */),
  "component---src-templates-promotions-promotion-detail-tsx": () => import("./../../../src/templates/promotions/promotion-detail.tsx" /* webpackChunkName: "component---src-templates-promotions-promotion-detail-tsx" */)
}

